<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card>
          <div>
            <validation-observer ref="questionValidation">
              <b-form @submit.prevent="validationForm">
                <b-row>
                  <!-- Field: title_fr -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre fr"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre fr"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title_fr"
                          placeholder="Quelle équipe marquera le plus de but ?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_fr']"
                          class="text-danger"
                        >{{ server_errors['title_fr'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_en -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre en"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre en"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title_en"
                          placeholder="Which team will score the most goals?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_en']"
                          class="text-danger"
                        >{{ server_errors['title_en'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_de -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre de"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre de"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title_de"
                          placeholder="Welche Mannschaft wird die meisten Tore schießen?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_de']"
                          class="text-danger"
                        >{{ server_errors['title_de'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_it -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre it"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre it"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title_it"
                          placeholder="Quale squadra segnerà più gol?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_it']"
                          class="text-danger"
                        >{{ server_errors['title_it'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: title_es -->
                  <b-col
                    cols="12"
                    lg="6"
                  >
                    <b-form-group
                      label="Titre es"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="titre es"
                        rules="required|max:200"
                      >
                        <b-form-input
                          v-model="question.title_es"
                          placeholder="¿Qué equipo marcará más goles?"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['title_es']"
                          class="text-danger"
                        >{{ server_errors['title_es'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: points_to_win -->
                  <b-col
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-group
                      label="Points"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="points"
                        rules="required|max_value:99|min_value:1"
                      >
                        <b-form-input
                          id="slot"
                          v-model="question.points_to_win"
                          type="number"
                          placeholder="10"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['points_to_win']"
                          class="text-danger"
                        >{{ server_errors['points_to_win'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: status -->
                  <b-col
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-group
                      label="Status"
                    >
                      <v-select
                        v-model="question.status"
                        :dir="'ltr'"
                        :options="statusOptions"
                        :clearable="false"
                        :reduce="val => val.value"
                      />
                      <small
                        v-if="server_errors && server_errors['status']"
                        class="text-danger"
                      >{{ server_errors['status'][0] }}</small>
                    </b-form-group>
                  </b-col>

                  <!-- Field: date_time -->
                  <b-col
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-group
                      label="Date Limite"
                    >
                      <flat-pickr
                        v-model="question.date_time"
                        class="form-control"
                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss', time_24hr : true}"
                      />
                      <small
                        v-if="server_errors && server_errors['date_time']"
                        class="text-danger"
                      >{{ server_errors['date_time'][0] }}</small>
                    </b-form-group>
                  </b-col>

                  <!-- Field: is_visible -->
                  <b-col
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <b-form-group
                      label="Visibilité"
                    >
                      <b-form-checkbox
                        v-model="question.is_visible"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label">{{ question.is_visible ? 'Visible' : 'Invisible' }}</span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Action Buttons -->
                <div class="d-flex justify-content-between">
                  <b-button
                    variant="primary"
                    type="submit"
                    :disabled="formLoading"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  >
                    <b-spinner
                      v-show="formLoading"
                      small
                    />
                    Enregistrer
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import {
  max, max_value, min, min_value, required,
} from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'CompetitionsViewQuestionCreate',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BFormCheckbox,
    vSelect,
    BSpinner,
    flatPickr,
  },
  data() {
    return {
      loading: true,
      formLoading: false,
      question: {
        title_fr: '',
        title_en: '',
        title_de: '',
        title_it: '',
        title_es: '',
        points_to_win: 10,
        status: 'PENDING',
        is_visible: false,
        date_time: new Date(),
        competition_id: this.$route.params.idCompetition,
      },
      required,
      max,
      min,
      max_value,
      min_value,
      server_errors: null,
      statusOptions: [
        { label: 'À venir', value: 'PENDING' },
        { label: 'En cours', value: 'RUNNING' },
        { label: 'Terminé', value: 'FINISHED' },
      ],
    }
  },
  mounted() {
    this.$store.commit('app/UPDATE_PAGE_META', {
      pageTitle: 'Ajouter question',
      breadcrumb: [
        {
          text: 'Competitions',
          active: false,
          to: {
            name: 'competitions',
          },
        },
        {
          text: this.$route.params.idCompetition,
          active: false,
          to: {
            name: 'competitions-view',
            params: { id: this.$route.params.idCompetition },
          },
        },
        {
          text: 'ajouter une question',
          active: true,
        },
      ],
    })
  },
  beforeDestroy() {
    this.$store.commit('app/UPDATE_PAGE_META', { pageTitle: '', breadcrumb: [] })
  },
  methods: {
    async submitFormQuestion() {
      try {
        this.formLoading = true
        this.server_errors = null
        const createdQuestion = await axiosIns.post('/questions', this.question)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La question à bien été ajoutée',
            },
          },
          { timeout: 5000 },
        )
        this.formLoading = false
        this.$router.push({
          path: `/competitions/${this.$route.params.idCompetition}/question/${createdQuestion.data.data.uuid}`,
        })
      } catch (err) {
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.formLoading = false
      }
    },
    validationForm() {
      this.$refs.questionValidation.validate().then(success => {
        if (success) {
          this.submitFormQuestion()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
